/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC150M
 * 화면 설명: 후보자등록
 * 화면 접근권한: 일반사용자, 리크루팅본사스텝 (U000, F110, F102, R100)
 */
<template>
  <ur-page-container :show-title="true" title="후보자등록" type="page" @on-header-left-click="fn_Back">
    <ur-box-container direction="column" alignV="start">
      <mo-validate-watcher ref="vWatcher">
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-area">
            <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="input-box mb20">
              <div class="verify-birth ns-style2">
                <mo-text-field ref="error" readonly v-model="custCardVO.knbFrno" :rules="validateRule1" underline class="birth-input" placeholder="생년월일"/>
                <span>-</span>
                <mo-text-field ref="error" v-if="!this.$commonUtil.isMobile()" v-model="fn_KnbBkno" :rules="validateRule1" type="password" underline class="birth-input" placeholder="뒷 7자리" maxlength="7" :disabled="isExistCust"/>
                <m-trans-key-input v-else class="birth-input3" v-model="fn_KnbBkno"  :type="'numberMax7'" :start="'1'" :end="'-1'" 
                  :rules="validateRule1" @mask-type="fn_getMaskType" :disabled="isExistCust"/>
              </div>
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="input-box mb40">
              <div class="ns-round-btn ns-style2">
                <mo-button class="ns-btn-round" @click="fn_btnSelCust" :disabled="isExistCust">고객조회</mo-button>
              </div>
            </ur-box-container>
            
            <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="input-box mb20">
              <mo-text-field readonly v-model="custCardVO.custNm" underline placeholder="고객명"/>
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="input-box mb20">
              <div class="verify-phone">
                <mo-text-field v-model="custCardVO.celno" mask="phone" underline placeholder="000-0000-0000" readonly />
              </div>
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="input-box">
              <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-segment">
                <mo-segment-wrapper solid primary v-model="prpinSc">
                  <mo-segment-button value="N">일반</mo-segment-button>
                  <mo-segment-button value="R">손보</mo-segment-button>
                </mo-segment-wrapper>
              </ur-box-container>
            </ur-box-container>
          </ur-box-container>
      </mo-validate-watcher>
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" 
            :disabled="isNext" @click="fn_btn_next">완료합니다</mo-button>
        </div>
      </ur-box-container>
      <!-- 고객등록화면 이동-->
      <!--
      <mo-modal ref="modal" title="Title" no-header>
        <template>
          <span>{{ confirmMsg }}</span>          
        </template>
        <template slot="action">
          <mo-button @click="fn_OnNegative()">취소</mo-button>
          <mo-button @click="fn_AgreeConfirmYes()" main>확인</mo-button>
        </template>
      </mo-modal>
      -->
      <!-- 종료 안내 bottomSheet -->
      <mo-bottom-sheet ref="bottomSheetBackCnfrm" class="ns-bottom-sheet ns-style3">
        <div>
        <p class="cancel-ment">
          리크루팅동의를 종료하시겠습니까?<br/> 동의종료 선택시 작성된 후보자정보는 저장되지 않으며,<br/> 리크루팅동의는 완료되지 않습니다.
        </p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseBackCnfrmPop('Cancel')">취소</mo-button>
              <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CloseBackCnfrmPop('Close')">동의종료</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <mo-bottom-sheet ref="modalFrm" class="ns-bottom-sheet ns-style3">
        <div>
        <p class="cancel-ment" v-html="confirmMsg">
        </p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_OnNegative()">취소</mo-button>
              <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_AgreeConfirmYes()">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- Toast -->
      <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
import Screen from '~systems/mixin/screen'
import Msg from '@/systems/webkit/msg/msg'
// import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
export default {
  name: 'MSPRC150M',
  screenId: 'MSPRC150M',
  mixins: [Screen],
  components: {
  },
  data() {
    return {
      custCardVO: {},
      custCardRegMdfcVO: {},
      custInfo: {},
      isExistCust: false, // 등록유무
      isNext: true,
      confirmMsg: '',
      finishMsg: '등록되었습니다',
      prpinSc: 'N', // 디폴트는 일반
      validateRule1: [
        v => !!v || '필수입력항목입니다'
      ],
      encKey: '',
      isBackBtn: false, //backBtn 여부
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPRC150M')
  },
  created() {
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked) // listen to Event

    if (!this.$bizUtil.isEmpty(this.$route.params) && !this.$bizUtil.isEmpty(this.$route.params.custCardVO)) {
      this.custCardVO = this.$route.params.custCardVO
      this.custCardVO.knbBkno = ''
    }

    // 신규고객 / 필컨등록을 마치고 들어온 경우 기존 vuex에 저장되어 있는 데이터는 초기화함
    let tmpObj = this.getStore('rcStore').getters.getState.custInfo
    if (!this.$bizUtil.isEmpty(tmpObj.custNm)) {
      this.custCardVO.custNm = tmpObj.custNm
      this.custCardVO.knbFrno = tmpObj.knbFrno
      this.custCardVO.knbBkno = ''
      this.custCardVO.knbBknoBackup = ''
      this.custCardVO.knbBknoCipher = ''
      this.custCardVO.knbBknoCipherBackup = ''
      this.custCardVO.celno = tmpObj.celno

      this.getStore('rcStore').dispatch('DELETE', {}) // vuex에서 삭제함
    }
  },
  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked) // stop listenning Event
  },
  computed: {
    fn_KnbBkno: { // 주민번호 뒷자리 변경시 호출 - 전체주민번호변수(custCardVO.knb)에 변경 값 처리
      get: function () {
        let lv_KnbBkno = ''
        if (this.$commonUtil.isMobile()) {
          lv_KnbBkno = this.custCardVO.knbBknoCipher
        } else {
          lv_KnbBkno = this.custCardVO.knbBkno
        }
        return lv_KnbBkno
      },
      set: function (data) {
        if (this.$commonUtil.isMobile()) {
          this.custCardVO.knbBknoCipher = data
        } else {
          this.custCardVO.knbBkno = data
          this.custCardVO.knbBknoCipher = ''
        }
        this.custCardVO.knb = (this.$commonUtil.isMobile()) ? '' : this.custCardVO.knbFrno + data // 모바일일 경우 공백 처리, PC에서만 전체주민번호 평문으로 보관
      }
    },
    modal(){
      return this.$refs.modalFrm;
    }
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_CheckSsnValidation
    * 설명       : 주민번호 유효성 체크
    ******************************************************************************/
    fn_btnSelCust () {
      if (!this.$commonUtil.isMobile()) {
        if (this.custCardVO.knbBkno.length === 0) {
          this.getStore('confirm').dispatch('ADD', '주민등록번호를 입력하세요')
          return
        }
        this.fn_CheckSsnValidation()
      } else {
        if (this.$bizUtil.isEmpty(this.custCardVO.knbBknoCipher)) {
          this.getStore('confirm').dispatch('ADD', '주민등록번호를 입력하세요')
          return
        }
        this.fn_selCustCard()
      }
    },
    /******************************************************************************
    * Function명 : fn_CheckSsnValidation
    * 설명       : 주민번호 유효성 체크
    ******************************************************************************/
    fn_CheckSsnValidation () {
      let lv_Vm = this
      let tmpCustCardVO = Object.assign({}, lv_Vm.custCardVO) // hoshInfoVO 객체 복사
      let lv_Knb = ''
      if (!this.$commonUtil.isMobile()) { // PC 버전인 경우
        lv_Knb = tmpCustCardVO.knbFrno + tmpCustCardVO.knbBkno
      }
      let pParams = {
        knb: lv_Knb, // 전체 주민번호
        knbFrno: tmpCustCardVO.knbFrno, // 주민번호 앞번호
        knbBknoCipher: tmpCustCardVO.knbBknoCipher, // 주민번호 뒷번호
        knbScCd: lv_Vm.encKey // ios encrypt key
      }

      let trnstId = 'txTSSCM10S6'
      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.body !== null) {
            if (response.body.ssnYn === 'Y') {
              if (!lv_Vm.$commonUtil.isMobile()) {
                lv_Vm.fn_selCustCard()
              }
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            lv_Vm.custCardVO.knbBkno = ''
            lv_Vm.custCardVO.knbBknoCipher = ''
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_selCustCard
    * 설명       : 고객 조회. 고객식별번호로 고객존재 유무 확인
    ******************************************************************************/
    fn_selCustCard () {
      let lv_Vm = this
      let tmpCustCardVO = Object.assign({}, lv_Vm.custCardVO) // hoshInfoVO 객체 복사
      let lv_UserInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보
      let tmpKnbBkno = ''
      let tmpKnbBknoCipher = ''

      if (this.$commonUtil.isMobile()) {
        tmpKnbBknoCipher = tmpCustCardVO.knbBknoCipher
        tmpKnbBkno = tmpCustCardVO.knbBkno // ios키값
        if (tmpKnbBknoCipher === '') {
          this.getStore('confirm').dispatch('ADD', '주민등록번호를 입력하세요')
          return
        }
      } else {
        if (tmpCustCardVO.knbBkno.length === 0) {
          this.getStore('confirm').dispatch('ADD', '주민등록번호를 입력하세요')
          return
        } else {
          if (tmpCustCardVO.knbBkno.length < 7) {
            this.getStore('confirm').dispatch('ADD', '주민등록번호를 입력하세요')
            return
          } else {
            tmpKnbBkno = tmpCustCardVO.knbBkno
          }
        }
      }

      let pParams = {
        prcsfCd: '01',
        custNm: tmpCustCardVO.custNm,
        knbFrno: tmpCustCardVO.knbFrno,
        knbBknoCipher: tmpKnbBknoCipher,
        knbBkno: tmpKnbBkno,
        cnsltNo: lv_UserInfo.userId,
        celno: tmpCustCardVO.celno,
        infoPrcusCnsntTypCd: '18'
        // infoPrcusCnsntTypCd: '17' // 필수컨설팅 동의
      }
      let trnstId = 'txTSSRC10S2'
      console.log(pParams, trnstId)

      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.body !== null) {
            lv_Vm.custInfo = response.body
            if (lv_Vm.custInfo.custNewYn === 'Y' || lv_Vm.$bizUtil.isEmpty(lv_Vm.custInfo.chnlCustId)) { // 신규고객
              // 신규고객 or 마동, 필동 동의 X 고객
              lv_Vm.confirmMsg = '후보자 등록을 위해 필수 컨설팅 동의가 필요합니다.<br>고객동의 화면으로 이동하시겠습니까?' // '신규고객입니다.\n고객등록동의 화면으로 이동하시겠습니까?'
              lv_Vm.custCardVO.chnlCustId = 'NEW'
              lv_Vm.modal.open()
              /*
              let lv_ConfirmPop = lv_Vm.$bottomModal.open(MSPBottomAlert, {
                properties: {
                  title: '알림',
                  showHeader: false,
                  content: lv_Vm.confirmMsg,
                  title_pos_btn: "확인",
                  title_neg_btn: "취소"
                },
                listeners: {
                  onPopupConfirm: () => {
                    lv_Vm.$bottomModal.close(lv_ConfirmPop);
                    lv_Vm.fn_AgreeConfirmYes()
                  },
                  onPopupClose: () => {
                    lv_Vm.$bottomModal.close(lv_ConfirmPop);
                    lv_Vm.fn_OnNegative()
                  }
                }
              })
              */
              return
            } else {
              lv_Vm.custCardVO.chnlCustId = lv_Vm.custInfo.chnlCustId // 채널고객ID
              lv_Vm.custCardVO.chnlCstgrId = lv_Vm.custInfo.chnlCstgrId
              lv_Vm.custCardVO.chnlCustScCd = lv_Vm.custInfo.chnlCustScCd // 주민번호 확보(01:확보, 02:미확보)
              // 내외국인구분코드 세팅
              if (lv_Vm.custInfo.knbScCd === 'ZPER01') {
                lv_Vm.custCardVO.ntivFrgnScCd = '1' // 내국인
              } else if (lv_Vm.custInfo.knbScCd === 'ZPER02') {
                lv_Vm.custCardVO.ntivFrgnScCd = '9' // 외국인
              } else {
                lv_Vm.custCardVO.ntivFrgnScCd = ''
              }
              // [2021-08-11] 필수컨설팅 여부 확인
              // 기존 등록된 고객인 경우 필수컨설팅동의 여부 체크를 위해 채널고객ID로 고객 재조회한다.
              lv_Vm.fn_selCustCardInfo()
            }
            console.log('고객조회 결과 성공!')
          } else {
            console.log('고객조회 결과 response.body null....')
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_selCustCardInfo
    * 설명       : 기존고객인 경우 필수컨설팅 여부 체크
    ******************************************************************************/
    fn_selCustCardInfo () {
      var lv_Vm = this
      lv_Vm.isNext = true
      let trnstId = 'txTSSCM11S1'
      let pParams = {}
      let lv_UserInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보
      pParams.cnsltNo = lv_UserInfo.userId // 컨설턴트 사번
      pParams.chnlCustId = this.custCardVO.chnlCustId // 채널고객ID
      if (lv_Vm.DEBUG) console.log('fn_selCustCardInfo pParams : ', pParams)
      lv_Vm.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (!_.isEmpty(response.body)) {
            if (lv_Vm.DEBUG) console.log('fn_selCustCardInfo result : ', response.body)
            lv_Vm.custCardVO.mndtCnstgCnsntYn = '' // 필수컨설팅여부
            lv_Vm.custCardVO.mndtCnstgCnsntYmd = '' // 필수컨설팅동의종료일자
            lv_Vm.custCardVO.mndtCnstgCnsntYn = response.body.mndtCnstgCnsntYn // 필수컨설팅여부
            lv_Vm.custCardVO.mndtCnstgCnsntYmd = response.body.mndtCnstgCnsntYmd // 필수컨설팅동의종료일자
            if (lv_Vm.custCardVO.mndtCnstgCnsntYn === 'Y') {
              lv_Vm.isExistCust = true
              lv_Vm.isNext = false
              lv_Vm.$refs.snackbar.show({ text: '정상조회되었습니다' })
            } else {
              // 필수컨설팅동의 미동의 상태입니다.\n고객동의화면으로 이동하여 필수컨설팅동의를 진행하시길 바랍니다.
              lv_Vm.confirmMsg = '후보자 등록을 위해 필수 컨설팅 동의가 필요합니다.<br>고객동의 화면으로 이동하시겠습니까?' // '필수컨설팅동의 미동의 상태입니다.\n고객동의화면으로 이동하여 필수컨설팅동의를 진행하시길 바랍니다.'
              lv_Vm.modal.open()
              // lv_Vm.$refs.bottomSheetSaveBfCnfrm.open()
            }
          }
        })
        .catch(function (error) {
          // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          if (!_.isEmpty(error.message)) {
            lv_Vm.getStore('confirm').dispatch('ADD', error.message)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '시스템 오류가 발생했습니다. \n관리자에게 문의하여 주세요.') // 시스템 오류가 발생했습니다. \n관리자에게 문의하여 주세요.
          }
        })
    },
    /******************************************************************************
    * Function명 : fn_btn_next
    * 설명       : 다음 버튼 선택 시 호출되는 함수
    ******************************************************************************/
    fn_btn_next () {
      console.log('다음 ==================>')
      let lv_Vm = this
      let tmpCustCardVO = Object.assign({}, lv_Vm.custCardVO) // hoshInfoVO 객체 복사
      let knb = ''
      let tmpKnbBknoCipher = ''

      if (this.$commonUtil.isMobile()) {
        tmpKnbBknoCipher = tmpCustCardVO.knbBknoCipher
      } else {
        knb = tmpCustCardVO.knbFrno + tmpCustCardVO.knbBkno
        // tmpKnbBkno = tmpCustCardVO.knbBkno
      }
      if (tmpCustCardVO.celno !== ' ') {
        if (tmpCustCardVO.celno.indexOf('-') === -1) {
          tmpCustCardVO.celno = this.$bizUtil.telNoWithHyphen(tmpCustCardVO.celno)
        }
      }
      let trnstId = 'txTSSRC10I1'
      let pParams = {
        cnsltEno: this.getStore('userInfo').getters.getUserInfo.userId,
        chnlCustId: tmpCustCardVO.chnlCustId,
        knb: knb,
        knbFrno: tmpCustCardVO.knbFrno,
        knbBknoCipher: tmpKnbBknoCipher,
        knbScCd: lv_Vm.encKey, // ios encrypt key
        rrn: '',
        celno: tmpCustCardVO.celno,
        custNm: tmpCustCardVO.custNm,
        prpinSc: lv_Vm.prpinSc,
        fofCd: this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo,
        recmFcEno: this.getStore('userInfo').getters.getUserInfo.userId,
        recrCnsntYn: tmpCustCardVO.recrCnsntYn,
        tsCnsntYn: tmpCustCardVO.recrCnsntYn,
        natyCd: '',
        ntivFrgnScCd: tmpCustCardVO.ntivFrgnScCd
      }
      // console.log('다음 ==================>', pParams)
      lv_Vm.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (!lv_Vm.$bizUtil.isEmpty(response.body)) {
            if (response.body.sucsYn === 'Y') {
              if (!lv_Vm.$bizUtil.isEmpty(response.body.msgTxt)) { // 에러 메시지가 있는 경우에만 처리
                lv_Vm.finishMsg = response.body.msgTxt
              }
              lv_Vm.$refs.snackbar.show({ text: lv_Vm.finishMsg }) 
              // 후보자 리스트 화면으로 이동
              lv_Vm.$router.push({name: 'MSPRC200M'})
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.body.msgTxt)
            }
          }
        })
        .catch(function (error) {
          // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          if (!_.isEmpty(error.message)) {
            lv_Vm.getStore('confirm').dispatch('ADD', error.message)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '시스템 오류가 발생했습니다. \n관리자에게 문의하여 주세요.')
          }
        })
    },
    /******************************************************************************
    * Function명 : fn_AgreeConfirmYes
    * 설명       : 고객등록화면으로 이동
    ******************************************************************************/
    fn_AgreeConfirmYes () {
        let custInfo = {}
        custInfo.custNm = this.custCardVO.custNm
        custInfo.knbFrno = this.custCardVO.knbFrno
        custInfo.knbBkno = ''
        custInfo.celno = this.custCardVO.celno
        custInfo.chnlCustId = this.custCardVO.chnlCustId
        this.getStore('rcStore').dispatch('ADD', custInfo)

        // this.$router.push({name: 'TSSCM101M'})
        this.modal.close()
        this.$router.push({name: 'MSPCM001M', params: {infoPrcusCnsntTypCd: '17'}})
    },
    /******************************************************************************
     * Function명: fn_OnNegative
     * 설명: Confirm창 취소 버튼 클릭 함수
     ******************************************************************************/
    fn_OnNegative () {
      // '취소' 선택시 vuex 에 confirm 창을 닫았다고 저장한다.
      this.getStore('spStore').dispatch('SMS_CONFIRM', 'CLOSE')
      // confirm 창 닫음
      this.modal.close()
    },
    fn_AgreeConfirmNext () {
      this.$router.push({name: 'MSPRC200M'}) // 전체 후보자로 이동
    },
    fn_getMaskType (param) { // ios에서 넘어온 mtranskey key
      this.encKey = param
      this.custCardVO.knbBkno = this.encKey
    },
    /******************************************************************************
    * Function명: fn_Back
    * 설명: 헤더 영역 Back Button 파라미터 넘기기
    * Params: N/A
    * Return: N/A
    ******************************************************************************/
    fn_Back () {
      this.fn_CloseBackCnfrmPop('Open')
    },
    /******************************************************************************
    * Function명 : fn_CloseBackCnfrmPop
    * 설명       : 종료동의 안내 
    *           - OPEN(컨펌팝업열기), Cancel(화면유지), Close(동의종료) 선택에 대한 화면 이동
    ******************************************************************************/
    fn_CloseBackCnfrmPop (param) {

      switch(param){
        case 'Open':
          this.$refs.bottomSheetBackCnfrm.open()
          break
        case 'Cancel':
          this.$refs.bottomSheetBackCnfrm.close()
          break
        case 'Close':
          //Close : 프로세스 종료, 첫화면이동
          this.$refs.bottomSheetBackCnfrm.close()
          this.$router.push({name: 'MSPBC002M'})
          break
      }
    },
    /*********************************************************
     * Function명: fn_BackBtnClicked
     * 설명: 스마트폰 back 버튼 클릭 시 호출
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_BackBtnClicked () { // 스마트폰의 back 버튼 선택 시 처리
      if(isBackBtn){
        this.isBackBtn = false
        this.fn_CloseBackCnfrmPop('Close')
      }else{
        this.isBackBtn = true
        this.fn_CloseBackCnfrmPop('Open')
      }
    }
  }
}
</script>
